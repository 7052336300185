import React from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import CardsComponent from '../../components/CardsContainerComponent/CardsContainer';

import relatedPlaceholder from '../../../static/img/cws-placeholder-risorse-landing.png';

import './relatedCaseStudy.scss';

const RelatedCaseStudy = props => {
    if (!_get(props.relatedContentByTags, 'length')) return null;
    const filteredData = props.relatedContentByTags.filter(related => {
        return related.id !== props.contentId;
    }).map(related => {
      related.image = _isEmpty(related.image) ? { publicURL: relatedPlaceholder} : related.image;
      return related;
    }).sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });
    if (_get(filteredData, 'length', 0) <= 0) return null;
    return (
      <div className="related-case-study">
        <CardsComponent draggable className="article-related-contents" theme={props.theme} title={props.title} cards={filteredData} />
      </div>
  )
}

export default RelatedCaseStudy; // Sostituire per il renderizato dalla query

/* NOTES:
 Il risultato viene passato a CardsComponents tramite la proprietà cards,
 che si aspetta un array di oggetti dove ognuno dovrebbe avere una struttura cosi: 
 {
    image
    title
    description
    link
 }
*/

